import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanMatchFn, Route, RouterStateSnapshot, Routes } from "@angular/router";

import { EditorMode } from "@dicorp/html-ffe";

import { FfeEditorComponent, RecordManagerComponent, TrackingInfoComponent } from "src/components/hfe-components";
import { RoutingService, SessionService } from "src/services";

import { PortalRoutingService } from "../../models";
import { AdminBoardsService } from "../admin-boards.service";
import { ZappAppBoard } from "src/component-store";
import { ZappTransferComponent } from "../components";
import { UserStore } from "@dicorp/zappsmith-ngx-core";

export const ZAPP_DESIGNER_PATH = 'ZappDesigner';

@Injectable({
    providedIn: 'root'
})
export class ZappDesignerRoutingService implements PortalRoutingService {
    constructor(private adminBoardsService: AdminBoardsService,
        private sessionService: SessionService) {
    }

    getRouterRoute(): Promise<Route> {
        return new Promise<Route>(resolve => {
            this.adminBoardsService.getAdminBoards(ZAPP_DESIGNER_PATH).then(adminBoardsDict => {
                zappDesignerRoute.children.forEach(childRoute => {
                    const adminBoardKey = childRoute.data ? childRoute.data['adminBoardKey'] : undefined;
                    if (adminBoardKey && adminBoardsDict[adminBoardKey]) {
                        const zappAppBoard = adminBoardsDict[adminBoardKey];
                        childRoute.data = { zappAppBoard };
                        zappDesignerRoute.children = zappDesignerRoute.children.concat(this.createBoardRoutes(zappAppBoard));
                    }
                })

                resolve(zappDesignerRoute);
            });
        });
    }

    getToolbarRoute(): Promise<Route> {
        return new Promise<Route>(resolve => {
            this.adminBoardsService.getAdminBoards(ZAPP_DESIGNER_PATH).then(adminBoardsDict => {
                zappDesignerToolbarRoute.children.forEach(childRoute => {
                    const adminBoardKey = childRoute.data ? childRoute.data['adminBoardKey'] : undefined;
                    if (adminBoardKey && adminBoardsDict[adminBoardKey]) {
                        const zappAppBoard = adminBoardsDict[adminBoardKey];
                        childRoute.data = { zappAppBoard };
                    }
                })

                resolve(zappDesignerToolbarRoute);
            });
        });
    }

    private createBoardRoutes(zappAppBoard: ZappAppBoard): Routes {
        const boardRoutes: Routes = [];

        if (zappAppBoard.permissions.canView()) {
            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.viewRecordPath.replace(ZAPP_DESIGNER_PATH + '/', ''),
                data: {
                    zappAppBoard,
                    editorMode: EditorMode.View
                }
            });
        }

        if (zappAppBoard.permissions.canEdit()) {
            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.editRecordPath.replace(ZAPP_DESIGNER_PATH + '/', ''),
                data: {
                    zappAppBoard,
                    editorMode: EditorMode.Edit
                }
            });
        }

        if (zappAppBoard.permissions.canAdd()) {
            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.addRecordPath.replace(ZAPP_DESIGNER_PATH + '/', ''),
                data: {
                    zappAppBoard: zappAppBoard.AddBoard ? zappAppBoard.AddBoard : zappAppBoard,
                    editorMode: EditorMode.Add
                }
            });
        }

        if (this.sessionService.hasPermission('RecMgrViewHistoryAbility')) {
            boardRoutes.push({
                component: TrackingInfoComponent,
                path: zappAppBoard.detailsPath.replace(ZAPP_DESIGNER_PATH + '/', ''),
                data: {
                    zappAppBoard
                }
            });
        }

        return boardRoutes;
    }
}

@Injectable({
    providedIn: 'root'
})
class CanActivateZappAppModuleAbility implements CanActivate {
    constructor(private sessionService: SessionService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.sessionService.hasPermission('ZappAppModuleAbility');
    }
}

function canMatchZappAppModuleAbility(): CanMatchFn {
    return () => {
        return inject(CanActivateZappAppModuleAbility).canActivate(null, null);
    }
};

@Injectable({
    providedIn: 'root'
})
class CanActivateZappBoardModuleAbility implements CanActivate {
    constructor(private sessionService: SessionService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.sessionService.hasPermission('ZappBoardModuleAbility');
    }
}

function canMatchZappBoardModuleAbility(): CanMatchFn {
    return () => {
        return inject(CanActivateZappBoardModuleAbility).canActivate(null, null);
    }
};

@Injectable({
    providedIn: 'root'
})
class CanActivateZappTransfer implements CanActivate {
    constructor(private sessionService: SessionService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.sessionService.hasPermission('ZappTransferModuleAbility');
    }
}

function canMatchZappTransfer(): CanMatchFn {
    return () => {
        return inject(CanActivateZappTransfer).canActivate(null, null);
    }
};

@Injectable({
    providedIn: 'root'
})
class CanActivateZappDesignerModule implements CanActivate {
    constructor(
        private userStore: UserStore,
        private routingService: RoutingService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userStore?.activeUser?.isAdmin) {
            return true;
        } else {
            return this.routingService.canActivateAnyRoute(zappDesignerRoutes, routeSnapshot, state);
        }
    }
}

export const zappDesignerRoutes: Routes = [
    {
        title: 'Application',
        path: 'ZappApp',
        data: {
            adminBoardKey: 'zapp_app'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateZappAppModuleAbility],
        canMatch: [canMatchZappAppModuleAbility]
    },
    {
        title: 'Board',
        path: 'ZappBoard',
        data: {
            adminBoardKey: 'zapp_board'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateZappBoardModuleAbility],
        canMatch: [canMatchZappBoardModuleAbility]
    },
    {
        title: 'Zapp Doc',
        path: 'ZappDoc',
        data: {
            adminBoardKey: 'zapp_doc',
            hideToolbar: true
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateZappBoardModuleAbility],
        canMatch: [canMatchZappBoardModuleAbility]
    },
    {
        title: 'Zapp Job',
        path: 'ZappJob',
        data: {
            adminBoardKey: 'zapp_job',
            hideToolbar: true
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateZappBoardModuleAbility],
        canMatch: [canMatchZappBoardModuleAbility]
    },
    {
        title: 'Transfer',
        path: 'ZappTransfer',
        component: ZappTransferComponent,
        canActivate: [CanActivateZappTransfer],
        canMatch: [canMatchZappTransfer]
    },
    {
        title: 'Overview',
        path: 'ZappOverview',
        redirectTo: '/zs_diagram',
        canMatch: [canMatchZappBoardModuleAbility],
        data: {
            openPathInNewWindow: true,
            openPathFromOrigin: true,
        }
    },
];

const zappDesignerRoute: Route =
{
    title: 'Zapp Designer',
    path: 'ZappDesigner',
    children: zappDesignerRoutes,
    canActivate: [CanActivateZappDesignerModule]
};

const zappDesignerToolbarRoute: Route =
{
    title: 'Zapp Designer',
    path: 'ZappDesigner',
    children: zappDesignerRoutes.filter(route => { return !route.data || !route.data['hideToolbar'] }),
    canActivate: [CanActivateZappDesignerModule]
};

export const ZAPP_DESIGNER_BOARDS_JSON: any = {
    'zapp_app': {
        "_id": "zapp_app",
        "object_name": "zapp_app",
        "permission_base": "ZappApp::",
        "dataset": "ZappApp",
        "columnDefSetting": "ZappApp",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "ZappApp",
                "@name": "ZappApp"
            },
            "Id": {
                "#value": "ZappApp"
            },
            "Name": {
                "#value": "ZappApp"
            },
            "Label": {
                "#value": "Zapp App"
            },
            "PluralLabel": {
                "#value": "Zapp Apps"
            },
            "MainPackage": {
                "#value": "ZappApp_PKG"
            }
        }
    },
    'zapp_board': {
        "_id": "zapp_board",
        "object_name": "zapp_board",
        "permission_base": "ZappBoard::",
        "dataset": "ZappBoard",
        "columnDefSetting": "ZappBoard",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "ZappBoard",
                "@name": "ZappBoard"
            },
            "Id": {
                "#value": "ZappBoard"
            },
            "Name": {
                "#value": "ZappBoard"
            },
            "Label": {
                "#value": "Zapp Board"
            },
            "PluralLabel": {
                "#value": "Zapp Boards"
            },
            "MainPackage": {
                "#value": "ZappBoard_PKG"
            },
            "Parents": [
                {
                    "ParentBoardLink": {
                        "#value": "zapp_app",
                        "@id": "ZappApp",
                        "@name": "ZappApp"
                    },
                    "ParentKeyField": {
                        "#value": "Board.ApplicationLink.#value",
                    },
                    "DisableChildAdd": {
                        "#value": true,
                    }
                }
            ]
        }
    },
    'zapp_doc': {
        "_id": "zapp_doc",
        "object_name": "zapp_doc",
        "permission_base": "ZappDoc::",
        "dataset": "ZappDoc",
        "columnDefSetting": "ZappDoc",
        "versionObjectOverride": "/ne_document",
        "ExternalReferenceKey": "Document.ExternalReferenceKey",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "ZappDoc",
                "@name": "ZappDoc"
            },
            "Id": {
                "#value": "ZappDoc"
            },
            "Name": {
                "#value": "ZappDoc"
            },
            "Label": {
                "#value": "ZappDoc"
            },
            "PluralLabel": {
                "#value": "Zapp Docs"
            },
            "MainPackage": {
                "#value": "ZappDoc_PKG"
            },
            "Parents": [
                {
                    "ParentBoardLink": {
                        "#value": "zapp_board",
                        "@id": "ZappBoard",
                        "@name": "ZappBoard"
                    },
                    "ParentKeyField": {
                        "#value": "Document.ZappBoardLink.#value",
                    }
                }
            ]
        }
    },
    'zapp_job': {
        "_id": "zapp_job",
        "object_name": "zapp_job",
        "permission_base": "ZappJob::",
        "dataset": "ZappJob",
        "columnDefSetting": "ZappJob",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "ZappJob",
                "@name": "ZappJob"
            },
            "Id": {
                "#value": "ZappJob"
            },
            "Name": {
                "#value": "ZappJob"
            },
            "Label": {
                "#value": "ZappJob"
            },
            "PluralLabel": {
                "#value": "Zapp Jobs"
            },
            "MainPackage": {
                "#value": "ZappJob_PKG"
            },
            "Parents": [
                {
                    "ParentBoardLink": {
                        "#value": "zapp_board",
                        "@id": "ZappBoard",
                        "@name": "ZappBoard"
                    },
                    "ParentKeyField": {
                        "#value": "Job.ZappBoardLink.#value",
                    }
                }
            ]
        }
    }
}