import { Component } from '@angular/core';

@Component({
    selector: 'zs-csv-data-load',
    templateUrl: 'csv-data-load.component.html',
    styleUrls: ['csv-data-load.component.scss']
})
export class CsvDataLoadComponent {

}
