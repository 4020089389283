import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EditorMode, EditorOptions, EvalRuleResult } from '@dicorp/html-ffe';

import { CommonFunctions, ZappsmithWebService } from '@dicorp/zappsmith-ngx-core';
import { ZappAppBoard, ZappAppBoardStore } from 'src/component-store';
import { ConfirmationDialogService } from 'src/components/dialog-components';
import { AlertService, AlertType, GeneralDatasetService } from 'src/services';

import { HfeDatasetService } from '../hfe-services';
import { VersionCompareComponent, VersionCompareData } from '../version-compare/version-compare.component';

const TRACKING_INFO_FIELD = "TrackingInfo";
const CURRENT_INDEX_FIELD = "CurrentIndex";

const VIEW_VERSION_RULE = "VERSIONS_RULE";
const COMPARE_VERSION_RULE = "COMPARE_VERSION_RULE";
const RESTORE_VERSION_RULE = "RESTORE_VERSION_RULE";

@Component({
    selector: 'tracking-info',
    templateUrl: 'tracking-info.component.html',
    styleUrls: ['tracking-info.component.scss']
})
export class TrackingInfoComponent implements OnInit {
    public editorOptions: EditorOptions;
    public zappAppBoard: ZappAppBoard;
    private zappAppBoardParent: ZappAppBoard;

    private doc_id: string;
    private baseObject: string;

    private record: any;
    private versions: string[];

    private trackingInfo: any[];

    trackingInfoLoaded: boolean;

    private get activeRecord(): any {
        return this.editorOptions.html_ffe_api?.HtmlFFEService?.gets?.get_active_record();
    }

    private get currentIndex(): number {
        return CommonFunctions.resolvePathValue(CURRENT_INDEX_FIELD, this.activeRecord);
    }

    private get currentIndexVersion(): string {
        return this.trackingInfo[this.currentIndex]?._version?.toString();
    }

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private zappsmithWebService: ZappsmithWebService,
        private hfeDatasetService: HfeDatasetService,
        private alertService: AlertService,
        private generalDatasetService: GeneralDatasetService,
        private dialog: Dialog,
        private confirmationDialogService: ConfirmationDialogService,
        private zappAppBoardStore: ZappAppBoardStore) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(queryParams => {
            this.doc_id = queryParams['doc_id'];
            this.getTrackingInfo();

            if (queryParams['parentBoard']) {
                this.zappAppBoardParent = this.zappAppBoardStore.getZappAppBoardById(queryParams['parentBoard']);
            }
        });

        this.activatedRoute.data.subscribe(data => {
            this.zappAppBoard = data['zappAppBoard'];
            this.baseObject = this.zappAppBoard?.ffeEditorOptions?.baseObject;
            this.getTrackingInfo();
        });
    }

    private getTrackingInfo(): void {
        if (this.doc_id && this.baseObject) {
            const versionObject = this.zappAppBoard?.versionObjectOverride ? this.zappAppBoard?.versionObjectOverride : this.baseObject;
            this.generalDatasetService.get(versionObject, this.doc_id).then(
                record => {
                    this.record = record;
                    this.trackingInfo = [];

                    const track_list = this.record?.tracking_info as any[];
                    const cversion = this.record?._version;
                    const voffset: number = cversion - track_list.length; // was 1

                    track_list.forEach((value, index) => {
                        const version = index + voffset;
                        value.version = version;
                        this.trackingInfo.push(value);
                    });

                    this.generalDatasetService.list_versions(versionObject, this.doc_id).then(
                        list_versions => {
                            this.versions = [];
                            const listVersions = list_versions as any[];

                            listVersions.forEach((value, index) => {
                                this.trackingInfo.filter(trackingInfo => {
                                    return trackingInfo.version === value?._version;
                                }).forEach(trackingInfo => {
                                    trackingInfo._version = value._version;
                                    trackingInfo.versioned_at = value.versioned_at;
                                });

                                this.versions.push(value?._version);
                            });

                            this.versions.push("Latest");

                            // Remove version 0
                            // this.trackingInfo.splice(0, 1);
                            this.trackingInfo.reverse();
                            this.trackingInfoLoaded = true;

                            this.setupEditorOptions();
                        },
                        result => {
                            this.alertService.addAlert({
                                title: 'Error',
                                message: "Could not get archive record info",
                                type: AlertType.error
                            });
                        });
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not get orig record",
                        type: AlertType.error
                    });
                });
        }
    }

    private setupEditorOptions(): void {
        this.editorOptions = new EditorOptions();
        this.editorOptions.editorMode = EditorMode.View;

        this.editorOptions.diRunRule = (rule_name: string, record: any) => {
            return this.diRunRule(rule_name, record);
        }

        this.editorOptions.diCancel = () => {
            if (this.zappAppBoardParent) {
                return this.router.navigate([this.zappAppBoardParent?.recordManagerPath]);
            } else {
                return this.router.navigate([this.zappAppBoard?.recordManagerPath]);
            }
        }

        this.editorOptions.headers = [{
            label: this.zappAppBoard?.Label + ' Tracking Info',
            value: ''
        }]

        this.editorOptions.htmlFfeApiChanged.subscribe(api => {
            api?.load_dataset(this.createDataset());

            const datasetRecord: any = {};
            datasetRecord[TRACKING_INFO_FIELD] = this.trackingInfo;
            api?.reload_record(datasetRecord);
        });
    }

    private createDataset(): any {
        const datasetConfig = this.hfeDatasetService.getDatasetConfigUsingColumnDefs(TRACKING_INFO_FIELD, 'activity_log', ColumnDefs);

        const editorDataset: any = {
            sections: {
                "MainForm": {
                    "name": "MainForm",
                    "height": "100%",
                    "children": [
                        {
                            "dm_reference": TRACKING_INFO_FIELD,
                            "type": "Grid",
                            "align_label": "Hidden",
                            "height": "100%",
                            "number_of_rows": 1,
                            "current_index_field": CURRENT_INDEX_FIELD,
                            "additional_actions": this.getAdditionalActions(),
                            "children": datasetConfig.columns
                        }
                    ]
                }
            },
            field_configurations: datasetConfig.fieldConfigurations,
            rules: datasetConfig.rules,
            menus: datasetConfig.menus,
            default_date_format: "ISO8601",
            default_time_format: "string"
        };

        return editorDataset;
    }

    private getAdditionalActions(): any[] {
        return [
            {
                "label": "View",
                "rule": VIEW_VERSION_RULE
            },
            {
                "label": "Compare",
                "rule": COMPARE_VERSION_RULE
            },
            {
                "label": "Restore",
                "rule": RESTORE_VERSION_RULE
            }
        ]
    }

    private diRunRule(rule_name: string, record: any): Promise<EvalRuleResult[]> {
        switch (rule_name) {
            case VIEW_VERSION_RULE: {
                this.viewVersionRule();
                break;
            }
            case COMPARE_VERSION_RULE: {
                this.compareVersion();
                break;
            }
            case RESTORE_VERSION_RULE: {
                this.restoreVersion();
                break;
            }
        }

        return Promise.resolve([]);
    }

    private viewVersionRule(): void {
        this.router.navigate([this.zappAppBoard?.viewRecordPath], {
            queryParams: {
                doc_id: this.doc_id,
                version: this.currentIndexVersion,
                backOnClose: true
            }
        });
    }

    private compareVersion(): void {
        if (!this.currentIndexVersion) {
            this.alertService.addAlert({
                title: 'Error',
                message: "Invalid version at current index: " + this.currentIndexVersion,
                type: AlertType.error
            });

            return;
        }

        const dialogData: VersionCompareData = {
            baseObject: this.baseObject,
            doc_id: this.doc_id,
            startVersion: this.currentIndexVersion,
            versions: this.versions
        }
        this.dialog.open<void>(VersionCompareComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            data: dialogData
        });
    }

    private restoreVersion(): void {
        if (!this.currentIndexVersion) {
            this.alertService.addAlert({
                title: 'Error',
                message: "Invalid version at current index: " + this.currentIndexVersion,
                type: AlertType.error
            });

            return;
        }

        this.confirmationDialogService.openConfirmationDialog(
            {
                title: 'Restore Version',
                message: 'Are you sure you want to restore version: ' + this.currentIndexVersion + '?'
            }
        ).then(
            result => {
                if (result) {
                    this.generalDatasetService.restore_version(this.baseObject, this.doc_id, this.currentIndexVersion).then(
                        result => {
                            this.router.navigate([this.zappAppBoard?.recordManagerPath]);
                        },
                        result => {
                            this.alertService.addAlert({
                                title: 'Error',
                                message: "Could not run restore_version",
                                type: AlertType.error
                            });
                        });
                }
            }
        );
    }
}

const VersionColumn: any = {
    heading: 'Version',
    field: 'version',
    kind: 'int',
    active: true
}

const FacilityColumn: any = {
    heading: 'Facility',
    field: 'facility.name',
    kind: 'text',
    active: true
}

const UserColumn: any = {
    heading: 'User',
    field: 'user.name',
    kind: 'text',
    active: true
}

const WhenColumn: any = {
    heading: 'Created',
    field: 'when',
    kind: 'datetime',
    active: true
}

const ReplacedWhenColumn: any = {
    heading: 'Replaced',
    field: 'versioned_at',
    kind: 'datetime',
    active: true
}

const ColumnDefs = [
    VersionColumn,
    FacilityColumn,
    UserColumn,
    WhenColumn,
    ReplacedWhenColumn
]
