import { Component } from '@angular/core';

@Component({
    selector: 'zs-system-update',
    templateUrl: 'system-update.component.html',
    styleUrls: ['system-update.component.scss']
})
export class SystemUpdateComponent {

}
