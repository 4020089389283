import { Component } from '@angular/core';

@Component({
    selector: 'zs-ravs-migrate',
    templateUrl: 'ravs-migrate.component.html',
    styleUrls: ['ravs-migrate.component.scss']
})
export class RavsMigrateComponent {

}
