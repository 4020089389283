import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanMatchFn, Route, RouterStateSnapshot, Routes, UrlSegment } from "@angular/router";

import { UserStore } from "@dicorp/zappsmith-ngx-core";

import { PortalRoutingService } from "../models";

import {
    SystemUpdateComponent, RawSystemConfigurationComponent,
    EnvironmentConfigurationComponent, SequenceAdminComponent,
    MigrateCollectionComponent, RavsMigrateComponent, RavsRestoreComponent,
    CsvDataLoadComponent, BoardDataLoadComponent, BoardDataPassComponent,
    DataTransformComponent, DatasetManagerComponent, MenuManagerComponent,
    NodeEditorProjectComponent, NodeEditorNodeKindComponent, NodeEditorTransferComponent,
    RawReportQueryComponent, ReportDomainComponent, SystemConfigurationComponent
} from "./components";

@Injectable({
    providedIn: 'root'
})
export class SystemRoutingService implements PortalRoutingService {
    constructor() {
    }

    getRouterRoute(): Promise<Route> {
        return Promise.resolve(systemRoute);

    }

    getToolbarRoute(): Promise<Route> {
        return Promise.resolve(systemToolbarRoute);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CanActivateSystemModule implements CanActivate {
    constructor(private userStore: UserStore) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.userStore?.activeUser?.isAdmin;
    }
}

const systemRoutes: Routes = [
    {
        title: 'System Update',
        path: 'system_update',
        component: SystemUpdateComponent
    },
    {
        title: 'System Configuration',
        path: 'configuration',
        component: SystemConfigurationComponent
    },
    {
        title: 'Raw System Configuration',
        path: 'raw_configuration',
        component: RawSystemConfigurationComponent
    },
    {
        title: 'Environment Configuration',
        path: 'environment_configuration',
        component: EnvironmentConfigurationComponent
    },
    {
        title: 'Sequence Admin',
        path: 'sequence',
        component: SequenceAdminComponent
    },
    {
        title: 'Migrate Collection (Rpt)',
        path: 'migrate_collection',
        component: MigrateCollectionComponent
    },
    {
        title: 'RAVS Migrate',
        path: 'ravs_migrate',
        component: RavsMigrateComponent
    },
    {
        title: 'RAVS Restore',
        path: 'ravs_restore',
        component: RavsRestoreComponent
    },
    {
        title: 'CSV Data Load',
        path: 'csv_data_load',
        component: CsvDataLoadComponent
    },
    {
        title: 'Board Data Load',
        path: 'board_data_load',
        component: BoardDataLoadComponent
    },
    {
        title: 'Board Data Pass',
        path: 'board_data_pass',
        component: BoardDataPassComponent
    },
    {
        title: 'Data Transform',
        path: 'data_transform',
        component: DataTransformComponent
    },
    {
        title: 'Dataset Manager',
        path: 'dataset',
        component: DatasetManagerComponent
    },
    {
        title: 'Menu Manager',
        path: 'menu',
        component: MenuManagerComponent
    },
    {
        title: 'Menu Manager',
        path: 'menu',
        component: MenuManagerComponent
    },
    // {
    //     title: 'FFE Setting',
    //     path: 'ffe_setting',
    //     component: FfeSettingComponent
    // }
    {
        title: 'Node Editor Project',
        path: 'ne_project',
        component: NodeEditorProjectComponent
    },
    {
        title: 'Node Editor Node Kind',
        path: 'ne_node_kind',
        component: NodeEditorNodeKindComponent
    },
    {
        title: 'Node Editor Transfer',
        path: 'ne_transfer',
        component: NodeEditorTransferComponent
    },
    {
        title: 'Raw Report Query',
        path: 'raw_report_query',
        component: RawReportQueryComponent
    },
    {
        title: 'Report Domain',
        path: 'report_domain',
        component: ReportDomainComponent
    },

]

const systemRoute: Route =
{
    title: 'System',
    path: 'System',
    children: systemRoutes,
    canActivate: [CanActivateSystemModule]
};

const systemToolbarRoute: Route =
{
    title: 'System',
    path: 'System',
    children: systemRoutes.filter(route => { return !route.data || !route.data['hideToolbar'] }),
    canActivate: [CanActivateSystemModule]
};