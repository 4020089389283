import { Dialog } from '@angular/cdk/dialog';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { EditorOptions } from '@dicorp/html-ffe';

import { BoardActionRule, GridView, ZappAppBoard, ZappGridViewStateStore } from 'src/component-store';
import { SessionService, UserPreferencesService } from 'src/services';

import { ColumnDefManagerComponent, ColumnDefManagerData } from '../column-def-manager/column-def-manager.component';
import { ConfirmationDialogService } from 'src/components/dialog-components';
import { Observable, map } from 'rxjs';
import { GridViewEditorComponent } from '../grid-view-editor/grid-view-editor.component';

@Component({
    selector: 'zs-hfe-grid-actions',
    templateUrl: 'hfe-grid-actions.component.html',
    styleUrls: ['hfe-grid-actions.component.scss']
})
export class HfeGridActionsComponent {
    @Input() editorOptions: EditorOptions;

    @Input() gridField: string;

    @Input() currentKeyValues: string[];

    @Input() actionRules: BoardActionRule[];

    @Output() refreshColumnsEvent = new EventEmitter<void>();
    @Output() runActionRuleEvent = new EventEmitter<BoardActionRule>();

    private _zappAppBoard: ZappAppBoard;
    get zappAppBoard(): ZappAppBoard {
        return this._zappAppBoard;
    }
    @Input() set zappAppBoard(value: ZappAppBoard) {
        if (value && this._zappAppBoard !== value) {
            this._zappAppBoard = value;
        }
    }

    get allowExport(): boolean {
        return !this.zappAppBoard?.disableExport && this.sessionService.hasPermission('RecMgrExportAbility');
    }

    // Action Rules
    get dropdownActionRules(): BoardActionRule[] {
        return this.actionRules?.filter(actionRule => {
            return !actionRule.standAlone;
        })
    }

    get standAloneActionRules(): BoardActionRule[] {
        return this.actionRules?.filter(actionRule => {
            return actionRule.standAlone;
        })
    }

    // View Methods
    get gridViews(): Observable<GridView[]> {
        return this.zappGridViewStateStore.observeGridViews(this.zappAppBoard?.gridViewsKey);
    }

    get latestDefaultGridView(): GridView {
        return this.zappGridViewStateStore.getLatestDefaultGridView(this.zappAppBoard?.gridViewsKey);
    }

    get activeGridView(): GridView {
        return this.zappGridViewStateStore.getGridView(
            this.zappAppBoard?.gridViewsKey,
            this.zappAppBoard?.gridState?.selectedViewKey
        );
    }

    get allowColumnEdit(): boolean {
        return !this.zappAppBoard?.disableColumnsAndViews &&
            this.zappAppBoard?.columnDefSetting &&
            this.sessionService.hasPermission('RecMgrColumnEditAbility');
    }

    get allowViews(): boolean {
        return !this.zappAppBoard?.disableColumnsAndViews && this.sessionService.hasPermission("RecMgrViewAbility");
    }

    get allowViewSave(): boolean {
        return this.sessionService.hasPermission("RmViewUpdateLevel") && this.activeGridView?.can_update;
    }

    get allowViewDelete(): boolean {
        return this.sessionService.hasPermission("RmViewDeleteLevel") && this.activeGridView?.can_delete;
    }

    get allowViewSaveAs(): boolean {
        return this.sessionService.hasPermission("RmViewAddLevel");
    }

    constructor(private sessionService: SessionService,
        private userPreferencesService: UserPreferencesService,
        private confirmationDialogService: ConfirmationDialogService,
        private dialog: Dialog,
        private zappGridViewStateStore: ZappGridViewStateStore) {
    }

    refresh(): void {
        this.editorOptions?.html_ffe_api?.refresh_grid_view(this.gridField);
    }

    resetGridView(): void {
        this.clearColumnState();
        this.refreshColumnsEvent.emit();
    }

    export(format: 'csv' | 'excel'): void {
        this.editorOptions?.html_ffe_api?.export_grid_data(this.gridField, format);
    }

    resetColumns(): void {
        this.clearColumnState();
        this.updateColumns(null);
    }

    editColumns(): void {
        const columnDefManagerData: ColumnDefManagerData = {
            columnDefs: this.zappAppBoard?.columnDefs,
            selectedColumns: this.getSelectedColumns()
        }

        const dialogRef = this.dialog.open<string[] | false>(ColumnDefManagerComponent, {
            height: '75%',
            data: columnDefManagerData,
        });

        dialogRef.closed.subscribe(result => {
            if (result) {
                this.updateColumns(result);
            }
        });
    }

    actionRuleClick(actionRule: BoardActionRule) {
        if (actionRule?.confirmationMessage) {
            this.confirmationDialogService.openConfirmationDialog(
                {
                    title: 'Perform Action',
                    message: actionRule.confirmationMessage
                }
            ).then(
                result => {
                    if (result) {
                        this.runActionRuleEvent.emit(actionRule);
                    }
                });
        } else {
            this.runActionRuleEvent.emit(actionRule);
        }
    }

    gridViewClick(gridView: GridView) {
        this.zappAppBoard.gridState.selectedViewKey = gridView._id;
        this.zappAppBoard.gridState.columnState = gridView.state?.columnState;
        this.zappAppBoard.gridState.filterModel = gridView.state?.filterModel;

        this.refreshColumnsEvent.emit();
    }

    handleSaveAsView(): void {
        this.editView({});
    }

    handleEditView(): void {
        this.editView(this.activeGridView);
    }

    handleSaveView(): void {
        this.saveView(this.activeGridView);
    }

    handleDeleteView(): void {
        this.confirmationDialogService.openConfirmationDialog(
            {
                title: 'Delete View',
                message: 'Are you sure you want to delete the current view?'
            }
        ).then(
            result => {
                if (result) {
                    this.zappGridViewStateStore.deleteView(this.zappAppBoard.gridViewsKey, this.activeGridView).then(
                        result => {
                            this.zappAppBoard.gridState.selectedViewKey = undefined;
                        }
                    );
                }
            });
    }

    private getSelectedColumns(): string[] {
        if (!this.activeGridView) {
            return this.userPreferencesService.getUserRecordManagerColumnList(this.zappAppBoard?.columnDefSetting);
        } else {
            const stateColumnList = this.zappAppBoard?.gridState?.columnState.filter(columnState => {
                return !columnState.hide;
            }).map(columnState => {
                return columnState.colId;
            });

            const selectedColumns = this.zappAppBoard?.columnDefs.filter(columnDef => {
                const field = columnDef.childField ? columnDef.field + '.' + columnDef.childField : columnDef.field;
                return stateColumnList?.indexOf(field) >= 0;
            }).map(columnDef => {
                return columnDef?.heading?.toString();
            });

            return selectedColumns;
        }
    }

    private updateColumns(columns: string[]) {
        if (!this.activeGridView) {
            this.userPreferencesService.saveUserRecordManagerColumnList(this.zappAppBoard?.columnDefSetting, columns).then(
                () => {
                    this.refreshColumnsEvent.emit();
                }
            );
        } else {
            const selectedColumnIds = this.zappAppBoard?.columnDefs.filter(columnDef => {
                return columns?.indexOf(columnDef?.heading?.toString()) >= 0;
            }).map(columnDef => {
                const field = columnDef.childField ? columnDef.field + '.' + columnDef.childField : columnDef.field;
                return field;
            });

            this.zappAppBoard?.gridState?.columnState?.forEach(columnState => {
                columnState.hide = selectedColumnIds.indexOf(columnState.colId) >= 0 ? false : true;
            })

            this.refreshColumnsEvent.emit();
        }
    }

    private clearColumnState(): void {
        delete this.zappAppBoard?.gridState?.selectedViewKey;
        delete this.zappAppBoard?.gridState?.columnState;
        delete this.zappAppBoard?.gridState?.filterModel;
    }

    private editView(gridView?: GridView): void {
        const dialogRef = this.dialog.open<GridView | false>(GridViewEditorComponent, {
            data: gridView?._id ? gridView : {},
        });

        dialogRef.closed.subscribe(result => {
            if (result) {
                this.saveView(result);
            }
        });
    }

    private saveView(gridView: GridView) {
        gridView.state = {
            columnState: this.editorOptions?.html_ffe_api?.get_grid_view(this.gridField),
            filterModel: this.editorOptions?.html_ffe_api?.get_grid_filter(this.gridField)
        };

        this.zappGridViewStateStore.saveGridView(this.zappAppBoard.gridViewsKey, gridView).then(
            gridViewToSave => {
                if (this.zappAppBoard?.gridState?.selectedViewKey !== gridViewToSave?._id) {
                    this.gridViewClick(gridViewToSave);
                }
            }
        );
    }
}
