import { Component, ElementRef, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import {
    CommonFunctions,
    FilterOperationType, FilterOperationValue,
    ZappsmithWebService, ZappsmithWebServiceApiParams, ZappsmithWebServiceBoardParams,
    ZappsmithWebServiceParamFilter, ZappsmithWebServiceParmDict, ZappsmithWebServiceQueryParams
} from '@dicorp/zappsmith-ngx-core';

import {
    EditorMode, EditorOptions,
    LinkToEntityOperation, LinkToEntityQueryParams, LinkToEntityQueryResult, EvalRuleResult, LinkToEntity, EditorEventInfo
} from '@dicorp/html-ffe';

import { BoardActionRule, GridState, ZappAppBoard, ZappAppBoardChild, ZappGridViewStateStore } from 'src/component-store';
import { AlertService, AlertType, DatasetUtilsService, RecordLockService, GeneralDatasetService, SessionService } from 'src/services';
import { DatasetConfig, HfeDatasetService } from '../hfe-services';
import { ConfirmationDialogService } from 'src/components/dialog-components';
import { HfeGridActionsComponent } from '../hfe-grid-actions/hfe-grid-actions.component';

const RECORD_MANAGER_FIELD = "RecordManagerGrid";
const CURRENT_INDEX_FIELD = "CurrentIndexId";
const CURRENT_INDICES_FIELD = "CurrentIndicesIds";
const VERSIONS_RULE = "VERSIONS_RULE";

@Component({
    selector: 'zs-record-manager',
    templateUrl: 'record-manager.component.html',
    styleUrls: ['record-manager.component.scss']
})
export class RecordManagerComponent implements OnInit {
    @ViewChild(HfeGridActionsComponent) gridActions: HfeGridActionsComponent;
    @ViewChildren(RecordManagerComponent) childRecordManagers: RecordManagerComponent[];

    private _zappAppBoard: ZappAppBoard;
    get zappAppBoard(): ZappAppBoard {
        return this._zappAppBoard;
    }
    @Input() set zappAppBoard(value: ZappAppBoard) {
        if (value && this._zappAppBoard !== value) {
            this._zappAppBoard = value;
            this.initialize();
        }
    }

    private _zappAppBoardChild: ZappAppBoardChild;
    get zappAppBoardChild(): ZappAppBoardChild {
        return this._zappAppBoardChild;
    }
    @Input() set zappAppBoardChild(value: ZappAppBoardChild) {
        if (value && this._zappAppBoardChild !== value) {
            this._zappAppBoardChild = value;
            this.isChildManager = true;
        }
    }

    @Input() hideTitle: boolean;
    @Input() fillHeight: boolean;

    get element(): HTMLElement {
        return (this.elementRef?.nativeElement as HTMLElement);
    }

    get showChildRecordManagers(): boolean {
        return !this.isChildManager && this.zappAppBoard?.children?.length > 0;
    }

    private _selectedChildTabIndex: number;
    get selectedChildTabIndex(): number {
        return this._selectedChildTabIndex;
    }
    @Input() set selectedChildTabIndex(value: number) {
        if (!this.isChildManager && this.zappAppBoard &&
            value >= 0 && this._selectedChildTabIndex !== value) {
            this._selectedChildTabIndex = value;
            this.zappAppBoard.lastSelectedChildIndex = value;
        }
    }

    public isChildManager: boolean;

    public editorOptions: EditorOptions;
    public recordManagerGridField = RECORD_MANAGER_FIELD;

    private editorDataset: any;
    private editorDatasetLoaded: boolean = false;

    private columnStateLoaded: boolean = false;
    private firstQueryRun: boolean = false;

    public currentKeyValue: string = '';
    public currentKeyValues: string[] = [];
    public currentParentKeyValue: string = '';

    public allowedActionRules: BoardActionRule[];
    private deleteSelectedActionRule: BoardActionRule;

    private get gridState(): GridState {
        return this.zappAppBoard?.gridState;
    }

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private datasetUtilsService: DatasetUtilsService,
        private recordLockService: RecordLockService,
        private generalDatasetService: GeneralDatasetService,
        private sessionService: SessionService,
        private alertService: AlertService,
        private zappsmithWebService: ZappsmithWebService,
        private elementRef: ElementRef,
        private hfeDatasetService: HfeDatasetService,
        private confirmationDialogService: ConfirmationDialogService,
        private zappGridViewStateStore: ZappGridViewStateStore) {
    }

    ngOnInit(): void {
        this.setupEditorOptions();
    }

    initialize(): void {
        this.editorDatasetLoaded = false;
        this.zappGridViewStateStore.getGridViews(this._zappAppBoard?.gridViewsKey).then(() => {
            this.createDataset();
            this.setAllowedActionRules();
        },
            () => {
                this.createDataset();
                this.setAllowedActionRules();
            });
    }

    refreshColumnsEvent(): void {
        this.editorDatasetLoaded = false;
        this.firstQueryRun = false;
        this.columnStateLoaded = false;

        this.createDataset();
    }

    runActionRule(actionRule: BoardActionRule) {
        // Check for actionOverride
        if (actionRule.actionOverride) {
            actionRule.actionOverride(this.currentKeyValues).then(() => {
                if (actionRule.refresh) {
                    this.refreshGridData();
                }

                if (actionRule.refreshChildren) {
                    this.childRecordManagers?.forEach(childRecordManager => {
                        childRecordManager.refreshGridData();
                    })
                }
            });
            return;
        }

        const item_links: any[] = [];
        if (this.currentKeyValue) {
            item_links.push({ '#values': this.currentKeyValue, "#value": this.currentKeyValue });
        }

        if (!actionRule.isEmptyRowsAllowed && item_links.length == 0) {
            this.alertService.addAlert({
                title: actionRule.label,
                message: "Please select rows to be acted on",
                type: AlertType.warning
            });
        } else {
            console.log("Running action " + actionRule.rule + "on " + "records " + item_links);

            const rule = {
                'name': actionRule.rule,
                'clisp_module': actionRule.rule,
                'kind': 'RUL_Eval',
                'bindings': [{ 'binding_type': 'String', 'value': item_links, 'name': 'recordKeys' }]
            };

            this.datasetUtilsService.runRulePromise(rule, null, null, null).then(
                result => {
                    if (result && !!(result.alert)) {
                        this.datasetUtilsService.handleRuleResultAlert(result).then(
                            data => {
                                this.runActionRule({ rule: data, label: data });
                            });
                    } else {
                        this.alertService.addAlert({
                            title: actionRule.label,
                            message: "Actions performed",
                            type: AlertType.success
                        });
                    }

                    this.refreshGridData();
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not perform " + actionRule.label,
                        type: AlertType.error
                    });
                });
        }
    }

    refreshGridData(): void {
        this.editorOptions?.html_ffe_api?.refresh_grid_view(this.recordManagerGridField);
    }

    private setupEditorOptions(): void {
        this.editorOptions = new EditorOptions();
        this.editorOptions.editorMode = EditorMode.Edit;

        this.editorOptions.diHasEntityPermission = (entity_name: string, operation: string) => {
            return this.hasEntityPermission(entity_name, operation);
        }

        this.editorOptions.diGetEntitiesQuery = (entity_name, params) => {
            return this.diGetEntitiesQuery(entity_name, params);
        }

        this.editorOptions.diViewEntity = (entity_name, key) => {
            return this.navigateToEntity(EditorMode.View, key);
        }

        this.editorOptions.diUpdateEntity = (entity_name, key, event) => {
            return this.diUpdateEntity(entity_name, key, event);
        }

        this.editorOptions.diAddEntity = (entity_name) => {
            this.navigateToEntity(EditorMode.Add);
            return Promise.reject();
        }

        this.editorOptions.diDeleteEntity = (entity_name, key) => {
            return this.deleteEntity(entity_name, key);
        }

        this.editorOptions.diRunRule = (rule_name: string, record: any) => {
            return this.diRunRule(rule_name, record);
        }

        if (!this.zappAppBoardChild) {
            this.activatedRoute.data.subscribe(data => {
                if (data['zappAppBoard']) {
                    this.zappAppBoard = data['zappAppBoard'];
                }
            });

            if (this.zappAppBoard?.lastSelectedChildIndex >= 0) {
                this.selectedChildTabIndex = this.zappAppBoard?.lastSelectedChildIndex;
            }

        } else {
            this.zappAppBoard = this.zappAppBoardChild.zappAppBoard;
            this.zappAppBoardChild.zappAppBoardParent?.currentParentKeyValue$.subscribe(currentParentKeyValue => {
                if (this.currentParentKeyValue !== currentParentKeyValue) {
                    this.currentParentKeyValue = currentParentKeyValue;
                    this.refreshGridData();
                }
            });
        }

        if (this.fillHeight) {
            this.element?.classList.add('fill-height');
        }

        if (this.zappAppBoard.diGetRowStyle) {
            this.editorOptions.diGetRowStyle = (field_name: string, row: any): any => {
                return this.zappAppBoard.diGetRowStyle(field_name, row);
            }
        }
    }

    private hasEntityPermission(entityName: string, operation: string, alertOnError: boolean = false): boolean {
        if (this.zappAppBoard?.disable_actions) {
            return false;
        }

        if (this.zappAppBoardChild?.zappAppBoardParent?.DisableActions) {
            return false;
        }

        switch (operation) {
            case LinkToEntityOperation.Search:
                return this.zappAppBoard.permissions.canView();
            case LinkToEntityOperation.Add: {
                if (this.zappAppBoard?.prevent_add) {
                    if (this.zappAppBoardChild?.zappAppBoardParent) {
                        if (this?.zappAppBoardChild?.zappAppBoardParent?.DisableActions) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                } else if (this?.zappAppBoardChild?.zappAppBoardParent?.DisableChildAdd) {
                    return false;
                }

                return this.zappAppBoard.permissions.canAdd();
            }
            case LinkToEntityOperation.Update:
                return this.zappAppBoard.permissions.canEdit();
            case LinkToEntityOperation.Delete: {
                if (this.zappAppBoard?.prevent_delete) {
                    return false;
                }
                return this.zappAppBoard.permissions.canDelete();
            }
            default:
                console.warn('Unknown entity operation: ' + operation);
                return false;
        }
    }

    private diGetEntitiesQuery(entity_name: string, params: LinkToEntityQueryParams): Promise<LinkToEntityQueryResult> {
        const queryParams: ZappsmithWebServiceQueryParams = {
            include_count: params.include_count,
            limit: params.limit,
            offset: params.offset,
            order_by: params.order_by,
            param_dict: params.param_dict
        }

        if (queryParams.order_by && Object.keys(queryParams.order_by).length === 0) {
            delete queryParams.order_by;
        }

        if (this.isChildManager) {
            // If this is a child record manager and there is not currentParenKeyValue, return 0 results
            if (!this.currentParentKeyValue) {
                const zeroQueryResult: LinkToEntityQueryResult = {
                    total_count: 0,
                    entities: []
                };
                return Promise.resolve(zeroQueryResult);
            }

            const parentKeyFilter: ZappsmithWebServiceParamFilter = {
                operation: FilterOperationValue.eq,
                type: FilterOperationType.string,
                values: [this.currentParentKeyValue]
            };

            (queryParams.param_dict as ZappsmithWebServiceParmDict)[this.zappAppBoardChild.zappAppBoardParent.ParentKeyField] = parentKeyFilter;
        }

        return new Promise<LinkToEntityQueryResult>((resolve, reject) => {
            const queryResolve = (result: any) => {
                const queryResult: LinkToEntityQueryResult = {
                    total_count: result?.count ? result.count : undefined,
                    entities: Array.isArray(result) ? result : result?.records
                };

                queryResult.entities.forEach((entity, index) => {
                    entity.key = (entity as any)._id
                });

                if (this.gridState?.lastCurrentKeyValues) {
                    setTimeout(() => {
                        this.editorOptions.html_ffe_api.set_grid_selected_rows(
                            RECORD_MANAGER_FIELD,
                            this.gridState?.lastCurrentKeyValues
                        )
                    });
                }

                const filterModel = this.editorOptions?.html_ffe_api?.get_grid_filter(this.recordManagerGridField);
                if (Object.keys(filterModel)?.length > 0) {
                    this.gridState.filterModel = filterModel;
                } else if (this.zappAppBoard.gridState.filterModel !== undefined) {
                    this.gridState.filterModel = undefined;
                }

                this.firstQueryRun = true;

                resolve(queryResult);

                if (!this.columnStateLoaded) {
                    this.columnStateLoaded = true;

                    if (this.gridState?.columnState) {
                        setTimeout(() => {
                            this.editorOptions?.html_ffe_api?.load_grid_view(this.recordManagerGridField, {
                                state: this.gridState?.columnState,
                                applyOrder: true
                            });
                        }, 100);
                    }
                } else {
                    this.gridState.columnState = this.editorOptions?.html_ffe_api?.get_grid_view(this.recordManagerGridField);
                }
            }

            if (this.gridState?.filterModel &&
                Object.keys(this.gridState?.filterModel).length > 0 &&
                !this.firstQueryRun) {

                resolve({ entities: [{} as any] });
                this.firstQueryRun = true;

                setTimeout(() => {
                    this.editorOptions?.html_ffe_api?.load_grid_filter(this.recordManagerGridField, this.gridState.filterModel);
                }, 500);
            } else if (this.zappAppBoard?.static_board) {
                const apiParams = new ZappsmithWebServiceApiParams(
                    this.zappAppBoard?.object_name,
                    queryParams);

                this.zappsmithWebService.getApi(apiParams).then(result => {
                    queryResolve(result);
                });
            } else {
                const boardParams = new ZappsmithWebServiceBoardParams(
                    this.zappAppBoard?.ApplicationLinkId,
                    this.zappAppBoard?.Id,
                    queryParams);
                this.zappsmithWebService.getBoard(boardParams).then(result => {
                    queryResolve(result);
                })
            }
        });
    }

    private diUpdateEntity(entity_name: string, key: string, event: EditorEventInfo): Promise<LinkToEntity> {
        const baseObject = this.zappAppBoard?.ffeEditorOptions?.baseObject;
        const lockKey = baseObject + key;

        this.recordLockService.lock(lockKey).then(
            result => {
                if (event?.doubleClick && this.zappAppBoard.doubleClickActionOverride) {
                    this.zappAppBoard?.doubleClickActionOverride(event.entity);
                } else {
                    this.navigateToEntity(EditorMode.Edit, key);
                }
            },
            result => {
                this.alertService.addAlert({
                    title: 'Error',
                    message: result?.statusText ? result?.statusText : result?.message,
                    type: AlertType.error
                });
            });

        return Promise.reject();
    }

    private navigateToEntity(editorMode: EditorMode, key?: string): Promise<any> {
        let path: string;
        let parentKeyField: string;
        let parentKey: string;

        switch (editorMode) {
            case EditorMode.View:
                path = this.zappAppBoard.viewRecordPath;
                break;
            case EditorMode.Edit:
                path = this.zappAppBoard.editRecordPath;
                break;
            case EditorMode.Add:
                path = this.zappAppBoard.addRecordPath;
                key = '-1';
                if (this.currentParentKeyValue && this.zappAppBoardChild?.zappAppBoardParent?.ParentKeyField) {
                    parentKeyField = this.zappAppBoardChild?.zappAppBoardParent?.ParentKeyField;
                    parentKey = this.currentParentKeyValue;
                }
                break;
        }

        const queryParams: Params = { doc_id: key };
        if (parentKeyField && parentKey) {
            queryParams['parentKeyField'] = parentKeyField;
            queryParams['parentKey'] = parentKey;
        };

        if (this.zappAppBoardChild?.zappAppBoardParent?.ParentKeyType) {
            queryParams['parentKeyType'] = this.zappAppBoardChild?.zappAppBoardParent?.ParentKeyType;
        }

        this.router.navigate([path], {
            queryParams
        });

        return Promise.reject();
    }

    private deleteEntity(entity_name: string, key?: string): Promise<void> {
        const baseObject = this.zappAppBoard?.ffeEditorOptions?.baseObject;

        if (!baseObject) {
            console.error();
            this.alertService.addAlert({
                title: 'Error',
                message: 'baseObject not defined on zappAppBoard.ffeEditorOptions.baseObject',
                type: AlertType.error
            });
            return Promise.reject();
        }

        return new Promise<void>((resolve, reject) => {
            const lockKey = baseObject + key;
            this.recordLockService.lock(lockKey).then(
                result => {
                    this.generalDatasetService.deleteItem(baseObject, key).then(
                        result => {
                            // force clear entities on delete
                            this.datasetUtilsService.clearEntityCache(baseObject, null);
                            resolve();
                        },
                        result => {
                            this.alertService.addAlert({
                                title: 'Error',
                                message: "Could not delete records",
                                type: AlertType.error
                            });
                            reject();
                        }
                    );
                },
                result => {
                    let message = '<b>Error Deleting Item</b>';
                    message += result.error?.message ? ': ' + result.error?.message : '';
                    this.alertService.addAlert({
                        title: 'Error',
                        message,
                        type: AlertType.error
                    });
                    reject();
                });
        });
    }

    private deleteEntities(keys?: string[]): Promise<void> {
        const baseObject = this.zappAppBoard?.ffeEditorOptions?.baseObject;

        if (!baseObject) {
            console.error();
            this.alertService.addAlert({
                title: 'Error',
                message: 'baseObject not defined on zappAppBoard.ffeEditorOptions.baseObject',
                type: AlertType.error
            });
            return Promise.reject();
        }

        return new Promise<void>((resolve, reject) => {
            this.generalDatasetService.deleteItems(baseObject, keys).then(
                result => {
                    // force clear entities on delete
                    this.datasetUtilsService.clearEntityCache(baseObject, null);
                    resolve();
                },
                result => {
                    this.alertService.addAlert({
                        title: 'Error',
                        message: "Could not delete records",
                        type: AlertType.error
                    });
                    reject();
                }
            );
        });
    }

    private loadDataset(): void {
        if (this.editorDatasetLoaded) {
            return;
        }

        // Load the default view
        if (this.zappAppBoard?.gridState?.defaultViewNeedsLoading) {
            this.zappAppBoard.gridState.defaultViewNeedsLoading = false;
            const defaultView = this.zappGridViewStateStore.getLatestDefaultGridView(this.zappAppBoard?.gridViewsKey);
            if (defaultView) {
                this.gridActions?.gridViewClick(defaultView);
            }
        }

        this.editorOptions?.html_ffe_api?.load_dataset(this.editorDataset);
        this.editorOptions?.html_ffe_api?.reload_record(this.zappAppBoard.recordManagerRecord);
    }

    private createDataset(): void {
        this.getRecordManagerDatasetConfig().then(datasetConfig => {
            // Add current index rule
            datasetConfig.rules.RUL_Eval = this.getEvalRules();

            this.editorDataset = {
                sections: {
                    "MainForm": {
                        "dm_reference": "Role",
                        "name": "MainForm",
                        "height": "100%",
                        "children": [
                            {
                                "dm_reference": RECORD_MANAGER_FIELD,
                                "type": "Grid",
                                "align_label": "Hidden",
                                "hide_grid_filter": true,
                                // "floating_grid_filter": true,
                                "allow_multi_select": true,
                                "allow_column_moving": true,
                                "show_grid_total": true,
                                "simple_floating_grid_filter": true,
                                "use_field_for_colId": true,
                                "height": "100%",
                                "current_index_field": CURRENT_INDEX_FIELD,
                                "current_indices_field": CURRENT_INDICES_FIELD,
                                "additional_actions": this.getAdditionalActions(),
                                "children": datasetConfig.columns,
                            }
                        ]
                    }
                },
                entity_map: this.zappAppBoard?.entityMap,
                field_configurations: datasetConfig.fieldConfigurations,
                rules: datasetConfig.rules,
                menus: datasetConfig.menus,
                default_date_format: "ISO8601",
                default_time_format: "string"
            };

            this.loadDataset();
        })
    }

    private setAllowedActionRules(): void {
        this.allowedActionRules = this.zappAppBoard?.actionRules?.filter(
            actionRule => {
                return !actionRule.restrictPermission || this.sessionService.hasPermission(actionRule.restrictPermission);
            });

        if (!this.allowedActionRules) {
            this.allowedActionRules = [];
        }

        if (!this.zappAppBoard?.disableDefaultActionRules && this.sessionService.isAdmin()) {
            this.deleteSelectedActionRule = {
                label: 'Delete Selected',
                confirmationMessage: "Are you sure you want to delete the selected items?",
                actionOverride: (currentKeyValues: string[]) => {
                    return this.deleteEntities(currentKeyValues);
                }
            }
            this.allowedActionRules?.unshift(this.deleteSelectedActionRule);
        }
    }

    private getAdditionalActions(): any[] {
        const additiontalActions: any[] = [];

        if (this.sessionService.hasPermission('RecMgrViewHistoryAbility') && !this.zappAppBoard.hideDetailsAction) {
            additiontalActions.push({
                "label": "Details",
                "rule": VERSIONS_RULE
            })
        }

        if (this.zappAppBoard?.boardAdditionalActions) {
            for (let key in this.zappAppBoard?.boardAdditionalActions) {
                const boardAdditionalAction = this.zappAppBoard?.boardAdditionalActions[key];

                additiontalActions.push({
                    "label": boardAdditionalAction?.label,
                    "rule": key
                })
            }
        }

        return additiontalActions;
    }

    private getEvalRules(): any[] {
        const evalRules: any[] = [];

        evalRules.push({
            "triggers": [
                {
                    "trigger_event": "Change",
                    "target": CURRENT_INDEX_FIELD
                }
            ],
            "clisp_module": CURRENT_INDEX_FIELD,
            "kind": "RUL_Eval",
            "name": CURRENT_INDEX_FIELD
        });

        evalRules.push({
            "triggers": [
                {
                    "trigger_event": "Change",
                    "target": CURRENT_INDICES_FIELD
                }
            ],
            "clisp_module": CURRENT_INDICES_FIELD,
            "kind": "RUL_Eval",
            "name": CURRENT_INDICES_FIELD
        });

        evalRules.push({
            "clisp_module": VERSIONS_RULE,
            "kind": "RUL_Eval",
            "name": VERSIONS_RULE
        });

        return evalRules;
    }

    private getRecordManagerDatasetConfig(): Promise<DatasetConfig> {
        return this.hfeDatasetService.getZappAppBoardDatasetConfig(this.zappAppBoard, RECORD_MANAGER_FIELD);
    }

    private diRunRule(rule_name: string, record: any): Promise<EvalRuleResult[]> {
        switch (rule_name) {
            case CURRENT_INDEX_FIELD: {
                this.currentIndexRule(record);
                break;
            }
            case CURRENT_INDICES_FIELD: {
                this.currentIndiciesRule(record);
                break;
            }
            case VERSIONS_RULE: {
                this.versionsRule(record);
                break;
            }
            default: {
                if (this.zappAppBoard?.boardAdditionalActions && this.zappAppBoard?.boardAdditionalActions[rule_name]) {
                    const boardAdditionalAction = this.zappAppBoard?.boardAdditionalActions[rule_name];
                    const selectedRows = this.editorOptions?.html_ffe_api?.get_grid_selected_rows(this.recordManagerGridField);
                    boardAdditionalAction.action(this.currentKeyValue, selectedRows).then(
                        result => {
                            if (result && boardAdditionalAction.refresh) {
                                this.refreshGridData();
                            }
                        }
                    );
                }
            }
        }

        return Promise.resolve([]);
    }

    private currentIndexRule(record: any): void {
        this.currentKeyValue = CommonFunctions.resolvePathValue(CURRENT_INDEX_FIELD, record);

        this.zappAppBoard.children.forEach(child => {
            child.zappAppBoardParent.setCurrentParentKeyValue(this.currentKeyValue);
        })
    }

    private currentIndiciesRule(record: any): void {
        const currentKeyValues = CommonFunctions.resolvePath(CURRENT_INDICES_FIELD, record);
        this.currentKeyValues = []
        if (Array.isArray(currentKeyValues)) {
            this.currentKeyValues = currentKeyValues.map(keyValue => {
                return CommonFunctions.getMdlObjectValue(keyValue);
            });

            this.zappAppBoard.gridState.lastCurrentKeyValues = this.currentKeyValues;
        }
    }

    private versionsRule(record: any): void {
        let currentKeyValue = this.currentKeyValue;
        if (this.zappAppBoard?.ExternalReferenceKey) {
            const selectedRows = this.editorOptions?.html_ffe_api?.get_grid_selected_rows(RECORD_MANAGER_FIELD);
            if (selectedRows?.length > 0) {
                const externalReferenceKey = CommonFunctions.resolvePathValue(this.zappAppBoard?.ExternalReferenceKey, selectedRows[0]);
                if (externalReferenceKey) {
                    currentKeyValue = externalReferenceKey;
                }
            }
        }

        this.router.navigate([this.zappAppBoard?.detailsPath], {
            queryParams: {
                doc_id: currentKeyValue
            }
        });
    }
}
