import { Component } from '@angular/core';

@Component({
    selector: 'zs-migrate-collection',
    templateUrl: 'migrate-collection.component.html',
    styleUrls: ['migrate-collection.component.scss']
})
export class MigrateCollectionComponent {

}
