import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanMatchFn, Route, RouterStateSnapshot, Routes, UrlSegment } from "@angular/router";

import { UserStore } from "@dicorp/zappsmith-ngx-core";
import { EditorMode } from "@dicorp/html-ffe";

import { FfeEditorComponent, RecordManagerComponent, TrackingInfoComponent } from "src/components/hfe-components";
import { RoutingService, SessionService } from "src/services";

import { PortalRoutingService } from "../../models";
import { AdminBoardsService } from "../admin-boards.service";
import { ZappAppBoard } from "src/component-store";
import { ActivityTrackingComponent } from "../components";

export const ADMIN_BOARDS_PATH = 'Admin';

@Injectable({
    providedIn: 'root'
})
export class AdminRoutingService implements PortalRoutingService {
    constructor(private adminBoardsService: AdminBoardsService,
        private sessionService: SessionService) {
    }

    getRouterRoute(): Promise<Route> {
        return new Promise<Route>(resolve => {
            this.adminBoardsService.getAdminBoards(ADMIN_BOARDS_PATH).then(adminBoardsDict => {
                adminRoute.children.forEach(childRoute => {
                    const adminBoardKey = childRoute.data ? childRoute.data['adminBoardKey'] : undefined;
                    if (adminBoardKey && adminBoardsDict[adminBoardKey]) {
                        const zappAppBoard = adminBoardsDict[adminBoardKey];
                        childRoute.data = { zappAppBoard };
                        adminRoute.children = adminRoute.children.concat(this.createBoardRoutes(zappAppBoard));
                    }
                })

                resolve(adminRoute);
            });
        });
    }

    getToolbarRoute(): Promise<Route> {
        return new Promise<Route>(resolve => {
            this.adminBoardsService.getAdminBoards(ADMIN_BOARDS_PATH).then(adminBoardsDict => {
                adminToolbarRoute.children.forEach(childRoute => {
                    const adminBoardKey = childRoute.data ? childRoute.data['adminBoardKey'] : undefined;
                    if (adminBoardKey && adminBoardsDict[adminBoardKey]) {
                        const zappAppBoard = adminBoardsDict[adminBoardKey];
                        childRoute.data = { zappAppBoard };
                    }
                })

                resolve(adminToolbarRoute);
            });
        });
    }

    private createBoardRoutes(zappAppBoard: ZappAppBoard): Routes {
        const boardRoutes: Routes = [];

        // boardRoutes.push({
        //     component: RecordManagerComponent,
        //     path: zappAppBoard.recordManagerPath,
        //     data: { zappAppBoard }
        // });

        if (zappAppBoard.permissions.canView()) {
            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.viewRecordPath.replace(ADMIN_BOARDS_PATH + '/', ''),
                data: {
                    zappAppBoard,
                    editorMode: EditorMode.View
                }
            });
        }

        if (zappAppBoard.permissions.canEdit()) {
            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.editRecordPath.replace(ADMIN_BOARDS_PATH + '/', ''),
                data: {
                    zappAppBoard,
                    editorMode: EditorMode.Edit
                }
            });
        }

        if (zappAppBoard.permissions.canAdd()) {
            boardRoutes.push({
                component: FfeEditorComponent,
                path: zappAppBoard.addRecordPath.replace(ADMIN_BOARDS_PATH + '/', ''),
                data: {
                    zappAppBoard: zappAppBoard.AddBoard ? zappAppBoard.AddBoard : zappAppBoard,
                    editorMode: EditorMode.Add
                }
            });
        }

        if (this.sessionService.hasPermission('RecMgrViewHistoryAbility')) {
            boardRoutes.push({
                component: TrackingInfoComponent,
                path: zappAppBoard.detailsPath.replace(ADMIN_BOARDS_PATH + '/', ''),
                data: {
                    zappAppBoard
                }
            });
        }

        return boardRoutes;
    }
}

@Injectable({
    providedIn: 'root'
})
class CanActivateIsAdmin implements CanActivate {
    constructor(private userStore: UserStore) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.userStore?.activeUser?.isAdmin;
    }
}

@Injectable({
    providedIn: 'root'
})
class CanActivateAdminRecordManager implements CanActivate {
    constructor(private userStore: UserStore) { }
    // constructor() { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userStore?.activeUser?.isAdmin) {
            return true;
        } else {
            const zappAppBoard: ZappAppBoard = route.data['zappAppBoard'];
            return zappAppBoard?.permissions.canAccessModule();
        }
    }
}

const canMatchAdminRecordManager: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
    const zappAppBoard: ZappAppBoard = route.data['zappAppBoard'];
    return zappAppBoard?.permissions.canAccessModule();
};

@Injectable({
    providedIn: 'root'
})
class CanActivateAdminModule implements CanActivate {
    constructor(
        private userStore: UserStore,
        private routingService: RoutingService) { }

    canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.userStore?.activeUser?.isAdmin) {
            return true;
        } else {
            return this.routingService.canActivateAnyRoute(adminRoutes, routeSnapshot, state);
        }
    }
}

const adminRoutes: Routes = [
    {
        title: 'Facilities',
        path: 'Facility',
        data: {
            adminBoardKey: 'facility'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Users',
        path: 'User',
        data: {
            adminBoardKey: 'user'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Accounts',
        path: 'Account',
        data: {
            adminBoardKey: 'account',
            hideToolbar: true
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Roles',
        path: 'Role',
        data: {
            adminBoardKey: 'role'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Staff',
        path: 'Staff',
        data: {
            adminBoardKey: 'staff'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Activity Tracking',
        path: 'ActivityTracking',
        component: ActivityTrackingComponent,
        canActivate: [CanActivateIsAdmin]
    },
    {
        title: 'Template Documents',
        path: 'TemplateDocument',
        data: {
            adminBoardKey: 'template_document'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Template Tags',
        path: 'TemplateTag',
        data: {
            adminBoardKey: 'template_tag'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Template Tag Groups',
        path: 'TemplateTagGroup',
        data: {
            adminBoardKey: 'template_tag_group'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Form Defaults',
        path: 'FormDefault',
        data: {
            adminBoardKey: 'form_default'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Custom Fields',
        path: 'CustomFieldDef',
        data: {
            adminBoardKey: 'custom_field_def'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Custom Menus',
        path: 'CustomMenuDef',
        data: {
            adminBoardKey: 'custom_menu_def'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'User Alerts',
        path: 'UserAlert',
        data: {
            adminBoardKey: 'user_alert'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Layout Configuration',
        path: 'LayoutConfiguration',
        data: {
            adminBoardKey: 'layout_configuration'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    },
    {
        title: 'Images',
        path: 'Image',
        data: {
            adminBoardKey: 'image'
        },
        canActivate: [CanActivateAdminRecordManager],
        component: RecordManagerComponent
    },
    {
        title: 'Published Documents',
        path: 'PublishedDocument',
        data: {
            adminBoardKey: 'pub_document'
        },
        component: RecordManagerComponent,
        canActivate: [CanActivateAdminRecordManager],
        canMatch: [canMatchAdminRecordManager]
    }
];

const adminRoute: Route =
{
    title: 'Admin',
    path: 'Admin',
    children: adminRoutes,
    canActivate: [CanActivateAdminModule]
};

const adminToolbarRoute: Route =
{
    title: 'Admin',
    path: 'Admin',
    children: adminRoutes.filter(route => { return !route.data || !route.data['hideToolbar'] }),
    canActivate: [CanActivateAdminModule]
};

export const ADMIN_BOARDS_JSON: any = {
    'facility': {
        "_id": "facility",
        "object_name": "facility",
        "permission_base": "Facility::",
        "dataset": "Facility",
        "columnDefSetting": "Facility",
        "static_board": true,
        "prevent_delete": true,
        "Board": {
            "ApplicationLink": {
                "@id": "Facility",
                "@name": "Facility"
            },
            "Id": {
                "#value": "Facility"
            },
            "Name": {
                "#value": "Facility"
            },
            "Label": {
                "#value": "Facility"
            },
            "PluralLabel": {
                "#value": "Facilities"
            },
            "MainPackage": {
                "#value": "Facility_PKG"
            }
        }
    },
    'role': {
        "_id": "role",
        "object_name": "role",
        "permission_base": "Role::",
        "dataset": "Role",
        "columnDefSetting": "Role",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "Role",
                "@name": "Role"
            },
            "Id": {
                "#value": "Role"
            },
            "Name": {
                "#value": "Role"
            },
            "Label": {
                "#value": "Role"
            },
            "PluralLabel": {
                "#value": "Roles"
            },
            "MainPackage": {
                "#value": "Role_PKG"
            }
        }
    },
    'user': {
        "_id": "user",
        "object_name": "user_no_admin",
        "permission_base": "User::",
        "dataset": "AppUser",
        "columnDefSetting": "AppUser",
        "facilityPermission": "UserAdminSearchLevel",
        "static_board": true,
        "prevent_delete": true,
        "Board": {
            "ApplicationLink": {
                "@id": "User",
                "@name": "User"
            },
            "Id": {
                "#value": "User"
            },
            "Name": {
                "#value": "User"
            },
            "Label": {
                "#value": "User"
            },
            "PluralLabel": {
                "#value": "Users"
            },
            "MainPackage": {
                "#value": "User_PKG"
            }
        }
    },
    'account': {
        "_id": "account",
        "object_name": "account",
        "permission_base": "Account::",
        "dataset": "Account",
        "columnDefSetting": "Account",
        "static_board": true,
        "hideDetailsAction": true,
        // "prevent_delete": true,
        "prevent_add": true,
        "Board": {
            "ApplicationLink": {
                "@id": "Account",
                "@name": "Account"
            },
            "Id": {
                "#value": "Account"
            },
            "Name": {
                "#value": "Account"
            },
            "Label": {
                "#value": "Account"
            },
            "PluralLabel": {
                "#value": "Accounts"
            },
            "MainPackage": {
                "#value": "Account_PKG"
            },
            "Parents": [
                {
                    "ParentBoardLink": {
                        "#value": "facility",
                        "@id": "Facility",
                        "@name": "Facility"
                    },
                    "ParentKeyField": {
                        "#value": "Account.FacilityLink.#value",
                    },
                    "DisableActions": {
                        "#value": true,
                    }
                },
                {
                    "ParentBoardLink": {
                        "#value": "role",
                        "@id": "Role",
                        "@name": "Role"
                    },
                    "ParentKeyField": {
                        "#value": "Account.RoleLink.#value",
                    },
                    "DisableActions": {
                        "#value": true,
                    }
                },
                {
                    "ParentBoardLink": {
                        "#value": "user",
                        "@id": "User",
                        "@name": "User"
                    },
                    "ParentKeyField": {
                        "#value": "Account.UserLink.#value",
                    },
                    "ParentKeyType": {
                        "#value": "app_user",
                    },
                    "DisableActions": {
                        "#value": false,
                    }
                }
            ]
        }
    },
    'template_document': {
        "_id": "template_document",
        "object_name": "template_document",
        "permission_base": "TemplateDocument::",
        "dataset": "TemplateDocument",
        "columnDefSetting": "TemplateDocument",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "TemplateDocument",
                "@name": "Template Document"
            },
            "Id": {
                "#value": "TemplateDocument"
            },
            "Name": {
                "#value": "Template Document"
            },
            "Label": {
                "#value": "Template Document"
            },
            "PluralLabel": {
                "#value": "Template Documents"
            },
            "MainPackage": {
                "#value": "TemplateDocument_PKG"
            }
        }
    },
    'template_tag': {
        "_id": "template_tag",
        "object_name": "template_tag",
        "permission_base": "TemplateTag::",
        "dataset": "TemplateTag",
        "columnDefSetting": "TemplateTag",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "TemplateTag",
                "@name": "Template Tag"
            },
            "Id": {
                "#value": "TemplateTag"
            },
            "Name": {
                "#value": "Template Tag"
            },
            "Label": {
                "#value": "Template Tag"
            },
            "PluralLabel": {
                "#value": "Template Tags"
            },
            "MainPackage": {
                "#value": "TemplateTag_PKG"
            }
        }
    },
    'template_tag_group': {
        "_id": "template_tag_group",
        "object_name": "template_tag_group",
        "permission_base": "TemplateTagGroup::",
        "dataset": "TemplateTagGroup",
        "columnDefSetting": "TemplateTagGroup",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "TemplateTagGroup",
                "@name": "Template Tag Group"
            },
            "Id": {
                "#value": "TemplateTagGroup"
            },
            "Name": {
                "#value": "Template Tag Group"
            },
            "Label": {
                "#value": "Template Tag Group"
            },
            "PluralLabel": {
                "#value": "Template Tag Groups"
            },
            "MainPackage": {
                "#value": "TemplateTagGroup_PKG"
            }
        }
    },
    'image': {
        "_id": "image",
        "object_name": "image",
        "permission_base": "Image::",
        "dataset": "Image",
        "columnDefSetting": "Image",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "Image",
                "@name": "Image"
            },
            "Id": {
                "#value": "Image"
            },
            "Name": {
                "#value": "Image"
            },
            "Label": {
                "#value": "Image"
            },
            "PluralLabel": {
                "#value": "Images"
            },
            "MainPackage": {
                "#value": "Image_PKG"
            }
        }
    },
    'custom_field_def': {
        "_id": "custom_field_def",
        "object_name": "custom_field_def",
        "permission_base": "CustomFieldDef::",
        "dataset": "CustomFieldDef",
        "columnDefSetting": "CustomFieldDef",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "CustomFieldDef",
                "@name": "Custom Field Def"
            },
            "Id": {
                "#value": "CustomFieldDef"
            },
            "Name": {
                "#value": "Custom Field Def"
            },
            "Label": {
                "#value": "Custom Field"
            },
            "PluralLabel": {
                "#value": "Custom Fields"
            },
            "MainPackage": {
                "#value": "CustomFieldDef_PKG"
            }
        }
    },
    'custom_menu_def': {
        "_id": "custom_menu_def",
        "object_name": "custom_menu_def",
        "permission_base": "CustomMenuDef::",
        "dataset": "CustomMenuDef",
        "columnDefSetting": "CustomMenuDef",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "CustomMenuDef",
                "@name": "Custom Menu Def"
            },
            "Id": {
                "#value": "CustomMenuDef"
            },
            "Name": {
                "#value": "Custom Menu Def"
            },
            "Label": {
                "#value": "Custom Menu"
            },
            "PluralLabel": {
                "#value": "Custom Menus"
            },
            "MainPackage": {
                "#value": "CustomMenuDef_PKG"
            }
        }
    },
    'pub_document': {
        "_id": "pub_document",
        "object_name": "pub_document",
        "permission_base": "PubDocument::",
        "dataset": "PubDocument",
        "columnDefSetting": "PubDocument",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "PublishedDocument",
                "@name": "Published Document"
            },
            "Id": {
                "#value": "PublishedDocument"
            },
            "Name": {
                "#value": "Published Document"
            },
            "Label": {
                "#value": "Published Document"
            },
            "PluralLabel": {
                "#value": "Published Documents"
            },
            "MainPackage": {
                "#value": "PubDocument_PKG"
            }
        }
    },
    'layout_configuration': {
        "_id": "layout_configuration",
        "object_name": "layout_configuration",
        "permission_base": "LayoutConfiguration::",
        "dataset": "LayoutConfiguration",
        "columnDefSetting": "LayoutConfiguration",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "LayoutConfiguration",
                "@name": "Layout Configuration"
            },
            "Id": {
                "#value": "LayoutConfiguration"
            },
            "Name": {
                "#value": "Layout Configuration"
            },
            "Label": {
                "#value": "Layout Configuration"
            },
            "PluralLabel": {
                "#value": "Layout Configurations"
            },
            "MainPackage": {
                "#value": "LayoutConfiguration_PKG"
            }
        }
    },
    'user_alert': {
        "_id": "user_alert",
        "object_name": "user_alert",
        "permission_base": "UserAlert::",
        "dataset": "UserAlert",
        "columnDefSetting": "UserAlert",
        "static_board": true,
        "Board": {
            "ApplicationLink": {
                "@id": "UserAlert",
                "@name": "User Alert"
            },
            "Id": {
                "#value": "UserAlert"
            },
            "Name": {
                "#value": "User Alert"
            },
            "Label": {
                "#value": "User Alert"
            },
            "PluralLabel": {
                "#value": "User Alerts"
            },
            "MainPackage": {
                "#value": "UserAlert_PKG"
            }
        }
    },
    'form_default': {
        "_id": "form_default",
        "object_name": "form_default",
        "permission_base": "FormDefault::",
        "dataset": "FormDefaultAdd",
        "add_dataset": "FormDefaultAdd",
        "datasetFieldLookup": "Context.ObjectName.#value",
        "columnDefSetting": "FormDefaultAdd",
        "static_board": true,
        "rulesActive": false,
        "Board": {
            "ApplicationLink": {
                "@id": "FormDefault",
                "@name": "Form Default"
            },
            "Id": {
                "#value": "FormDefault"
            },
            "Name": {
                "#value": "Form Default"
            },
            "Label": {
                "#value": "Form Default"
            },
            "PluralLabel": {
                "#value": "Form Default"
            },
            "MainPackage": {
                "#value": "FormDefault_PKG"
            }
        },
        "add_board": {
            "_id": "form_default_add",
            "object_name": "form_default",
            "permission_base": "FormDefault::",
            "add_dataset": "FormDefaultAdd",
            "columnDefSetting": "FormDefaultAdd",
            "static_board": true,
            "rulesActive": true,
            "manifest": {
                "hide_save_and_exit": true
            },
            "Board": {
                "ApplicationLink": {
                    "@id": "FormDefault",
                    "@name": "Form Default"
                },
                "Id": {
                    "#value": "FormDefault"
                },
                "Name": {
                    "#value": "Form Default"
                },
                "Label": {
                    "#value": "Form Default"
                },
                "PluralLabel": {
                    "#value": "Form Default"
                },
                "MainPackage": {
                    "#value": "FormDefault_PKG"
                }
            },
        }
    }
}