import { Component, OnInit } from '@angular/core';
import { EditorMode, EditorOptions, IHtmlFFEApi } from '@dicorp/html-ffe';

@Component({
    selector: 'bench-top',
    templateUrl: 'bench-top.component.html'
})
export class BenchTopComponent {
    public editorOptions: EditorOptions[];

    constructor() {
        // this.editorOptions = [];
        // for (let i = 0; i < 10; i++) {
        //     const editorOptions = new EditorOptions();
        //     editorOptions.editorMode = EditorMode.Edit;
        //     editorOptions.htmlFfeApiChanged.subscribe(api => {
        //         this.apiChanged(api);
        //     });
        //     this.editorOptions.push(editorOptions);
        // }
    }

    public apiChanged(html_ffe_api: IHtmlFFEApi): void {
        // const dataset = {
        //     sections: {
        //         "MainForm": {
        //             "dm_reference": "Role",
        //             "name": "MainForm",
        //             "height": "100%",
        //             "children": [
        //                 {
        //                     "display_name": "Button 1",
        //                     "type": "Button",
        //                     "auto_advance_enabled": false
        //                 },
        //                 {
        //                     "display_name": "Button 2",
        //                     "type": "Button",
        //                     "auto_advance_enabled": false
        //                 },
        //                 {
        //                     "display_name": "Button 3",
        //                     "type": "Button",
        //                     "auto_advance_enabled": false
        //                 }
        //             ]
        //         }
        //     },
        //     field_configurations: {},
        //     rules: {
        //         "kind": "RUL_Document",
        //         "name": "Role_RUL",
        //         "RUL_Skip": [
        //             {
        //                 "values": [
        //                     true
        //                 ],
        //                 "triggers": [
        //                     {
        //                         "trigger_event": "Change",
        //                         "target": "IsSkipped"
        //                     }
        //                 ],
        //                 "targets": [
        //                     {
        //                         "target": "UnitTest.Button.SimpleString"
        //                     }
        //                 ],
        //                 "kind": "RUL_Skip",
        //                 "name": "EqualSkipRule",
        //                 "operation": "Equal"
        //             }
        //         ]
        //     },
        //     menus: {
        //     }
        // };

        // html_ffe_api?.load_dataset(dataset);
        // html_ffe_api?.reload_record({});
    }
}
