import { Component } from '@angular/core';

@Component({
    selector: 'zs-ravs-restore',
    templateUrl: 'ravs-restore.component.html',
    styleUrls: ['ravs-restore.component.scss']
})
export class RavsRestoreComponent {

}
