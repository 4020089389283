import { Injectable } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";

import { ClientConfiguration, ClientConfigurationStore } from "@dicorp/zappsmith-ngx-auth";
import { API_INTERCEPTOR_IGNORE, ZappsmithWebService } from '@dicorp/zappsmith-ngx-core';

import { ClientModuleRulesService } from './client-module-rules.service';
import { RuleDefinition } from "src/common";

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    public queryParams: Params;

    public clientConfiguration: ClientConfiguration;
    public model: ConfigurationModel;

    private initialized: boolean;

    constructor(activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private zappsmithWebService: ZappsmithWebService,
        clientConfigurationStore: ClientConfigurationStore,
        private clientModuleRulesService: ClientModuleRulesService) {

        this.model = {
            configuration: null,
            app_title: "ZappSmith",
            login_prompt: "Sign in to ZappSmith",
            module_rules: [],
            copyright: "&copy; Copyright 2020 ZPD Solutions LLC. All Rights Reserved.",
            licenses: [],
            messageDocumentsUris: [],
        };

        clientConfigurationStore.activeClientConfiguration$.subscribe(activeClientConfiguration => {
            this.clientConfiguration = activeClientConfiguration;
            this.model.configuration = activeClientConfiguration.rawClientConfiguration;
            this.init();
        });

        activatedRoute.queryParams.subscribe(queryParams => {
            this.queryParams = queryParams;
            this.init();
        });
    }

    private init(): void {
        if (this.clientConfiguration && this.queryParams) {
            if (this.initialized) {
                return;
            }

            this.initialized = true;
            this.refreshConfiguration();
        }
    }

    refreshConfiguration() {
        this.buildModuleRules(); // refresh the module rules
        const orig_model_json = JSON.stringify(this.model.configuration)

        this.model.configuration = this.clientConfiguration.rawClientConfiguration;

        this.updateConfiguration().then(r => {
            // const new_model_json = JSON.stringify(this.model.configuration);
            // if (orig_model_json != 'null' && orig_model_json != new_model_json) {
            //     window.location.reload();
            // }

            // return this.model;
        });
    };

    updateConfiguration(): Promise<any[]> {
        if (!!(this.model.configuration.portal_app_title))
            this.model.app_title = this.model.configuration.portal_app_title;
        this.model.login_prompt = "Sign in to " + this.model.app_title;
        if (!!(this.model.configuration.portal_copyright))
            this.model.copyright = this.model.configuration.portal_copyright;
        if (!!(this.model.configuration.login_prompt))
            this.model.login_prompt = this.model.configuration.login_prompt;
        if (!!(this.model.configuration.licenses))
            this.model.licenses = this.model.configuration.licenses.split(',');

        // force user_preference into store
        if (!this.model.configuration.user_preference) {
            this.model.configuration.user_preference = {};
        }

        if (!!(this.model.configuration.top_disclaimer))
            this.model.top_disclaimer = this.model.configuration.top_disclaimer;
        if (!!(this.model.configuration.bottom_disclaimer))
            this.model.bottom_disclaimer = this.model.configuration.bottom_disclaimer;
        if (!!(this.model.configuration.pre_disclaimer_needed))
            this.model.pre_disclaimer_needed = this.model.configuration.pre_disclaimer_needed;

        this.model.configuration.trace_rule = this.queryParams["traceRule"];
        if (this.model.configuration.trace_rule) {
            this.model.licenses.push("TraceRule");
        }
        this.model.configuration.autoLogin = this.model.configuration.auto_login && this.queryParams["force"];
        if (this.model.configuration.autoLogin) {
            this.model.licenses.push("AutoLogin");
        } else {
            this.model.licenses.push("NoAutoLogin");
        }

        if (this.model.configuration.message_documents) {

            const uris = this.model.configuration.message_documents.split(',');
            this.model.messageDocumentsUris = [];
            // fill with blanks first
            uris.forEach((v: string, idx: number) => {
                this.model.messageDocumentsUris.push('');
            });
            let promises: Promise<any>[] = [];
            uris.forEach((v: string, idx: number) => {
                const url = v.trim();
                promises.push(new Promise<any>((resolve, reject) => {
                    this.http.get(url, {
                        responseType: 'text',
                        context: new HttpContext().set(API_INTERCEPTOR_IGNORE, true),
                        params: { cacheBuster: new Date().getTime() }
                    }).subscribe({
                        next: (result) => {
                            resolve(result);
                        },
                        error: (result: any) => {
                            resolve(result);
                        }
                    })
                }))
            });

            return new Promise<any[]>((resolve, reject) => {
                Promise.all(promises).then(
                    results => {
                        let idx: number = 0;
                        results.forEach((r) => { // (data,status,headers,config)
                            this.model.messageDocumentsUris[idx++] = r;
                        });
                        resolve(this.model.messageDocumentsUris);
                    },
                    results => {
                        resolve(this.model.messageDocumentsUris);
                    }
                )
            });
        } else {
            return Promise.resolve(this.model.messageDocumentsUris);
        }
    };

    getConfigurationItem(item: string, defaultValue: any = null) {
        return this.clientConfiguration.rawClientConfiguration[item] ?
            this.clientConfiguration.rawClientConfiguration[item] :
            defaultValue;
    };

    hasAnyLicense(licenses: string[]): boolean {
        for (let i = 0; i < licenses.length; i++) {
            const l = licenses[i];
            if (this.model.licenses.indexOf(l) >= 0 || this.model.licenses.indexOf('*') >= 0)
                return true;
        }
        return false;
    };

    buildModuleRules() {
        const rules = this.model.module_rules;
        if (rules.length == 0) {

            while (rules.length > 0) {
                rules.pop();
            }
            for (var k in this.clientModuleRulesService.clientModuleRules) {
                const v = this.clientModuleRulesService.clientModuleRules[k];
                let rd = (v.hasOwnProperty('rule')) ? <RuleDefinition>v : null;
                let definition = "";
                let bindings = "";
                let details = "";
                if (rd) {
                    if (!!rd.docs) {
                        definition = !!(rd.docs.definition) ? rd.docs.definition : '';
                        details = !!(rd.docs.details) ? rd.docs.details : '';
                        if (!!rd.docs.bindings && rd.docs.bindings.length > 0) {
                            bindings = "<style>table, th, td { border: 1px solid black; border-collapse: collapse;}</style>";
                            bindings += "<table><tr><th>Name</th><th>Description</th><th>Default</th></tr>";
                            rd.docs.bindings.forEach((v) => {
                                const name = (v.required ? "*" : '') + v.name;
                                const defaultValue = !!v.defaultValue ? v.defaultValue : ''
                                bindings += "<tr><td>" + name + "</td><td>" + v.description + "</td><td>" + defaultValue + "</td></tr>";
                            });
                            bindings += "</table>"
                            // console.log(bindings);
                        }
                    }
                }

                rules.push({
                    'name': k,
                    'location': 'client',
                    'definition': definition,
                    'details': details,
                    'bindings': bindings
                });
            };

            this.zappsmithWebService.get('/module_rule', {}).then(
                result => {
                    (result as any[]).forEach(v => {
                        // rules.push({'name': v, 'location': 'server'});
                        const name = v['name'];
                        const definition = v['definition'];
                        const bindings = v['bindings'];
                        rules.push({
                            'name': name,
                            'location': 'server',
                            'definition': definition,
                            // 'details': details,
                            'bindings': bindings
                        });
                    });

                    rules.sort(function (a, b) {
                        return (a.name > b.name) ? 1 : -1;
                    })
                },
                result => {
                    console.error(result)
                });
        }
    };

    getModuleRules() {
        return this.model.module_rules;
    };

    getRaw(): Promise<any> {
        return this.zappsmithWebService.get('/configuration', {});
    };

    saveRaw(configuration: any): Promise<any> {
        return this.zappsmithWebService.post('/configuration', { 'document': JSON.stringify(configuration) });
    };

    encryptString(config_string: string): Promise<any> {
        return this.zappsmithWebService.post('/configuration/encrypt_string', { 'config_string': config_string });
    }
}

export interface ConfigurationModel {
    configuration: any,
    app_title: string,
    login_prompt: string,
    copyright: string,
    module_rules: any[],
    licenses: string[],
    messageDocumentsUris: any[],

    top_disclaimer?: string,
    bottom_disclaimer?: string,
    pre_disclaimer_needed?: string
}