import { Component } from '@angular/core';

@Component({
    selector: 'zs-board-data-load',
    templateUrl: 'board-data-load.component.html',
    styleUrls: ['board-data-load.component.scss']
})
export class BoardDataLoadComponent {

}
