import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EditorMode, EditorOptions, LinkToEntityQueryParams, LinkToEntityQueryResult } from '@dicorp/html-ffe';

import {
    FilterOperationType, FilterOperationValue, ZappsmithWebService, ZappsmithWebServiceApiParams,
    ZappsmithWebServiceParmDict, ZappsmithWebServiceQueryParams
} from '@dicorp/zappsmith-ngx-core';

import { HfeDatasetService } from 'src/components/hfe-components';
import { SessionService } from 'src/services';

const ACTIVITY_TRACKING_FIELD = "ActivityTrackingGrid";

@Component({
    selector: 'zs-activity-tracking',
    templateUrl: 'activity-tracking.component.html',
    styleUrls: ['activity-tracking.component.scss']
})
export class ActivityTrackingComponent {
    public editorOptions: EditorOptions;
    public activityTrackingGridField = ACTIVITY_TRACKING_FIELD;

    public activityTrackingReports: ActivityTrackingReport[] = [];

    reportControl = new FormControl<ActivityTrackingReport>(null);

    constructor(private sessionService: SessionService,
        private zappsmithWebService: ZappsmithWebService,
        private hfeDatasetService: HfeDatasetService) {

        ReportList.forEach(report => {
            if (sessionService.hasPermission(report.permission)) {
                this.activityTrackingReports.push(report);
            }
        });

        this.reportControl.valueChanges.subscribe(report => {
            if (report) {
                this.editorOptions?.html_ffe_api?.refresh_grid_view(ACTIVITY_TRACKING_FIELD);
            }
        });
    }

    ngOnInit(): void {
        this.setupEditorOptions();
    }

    private setupEditorOptions(): void {
        this.editorOptions = new EditorOptions();
        this.editorOptions.editorMode = EditorMode.View;

        this.editorOptions.diHasEntityPermission = (entity_name: string, operation: string) => {
            return true;
        }

        this.editorOptions.diGetEntitiesQuery = (entity_name, params) => {
            return this.diGetEntitiesQuery(entity_name, params);
        }

        this.editorOptions.htmlFfeApiChanged.subscribe(api => {
            api?.load_dataset(this.createDataset());
            api?.reload_record({});
        });
    }

    private createDataset(): any {
        const columnDefs = this.reportControl.value?.columnDefs;
        const datasetConfig = this.hfeDatasetService.getDatasetConfigUsingColumnDefs(ACTIVITY_TRACKING_FIELD, 'activity_log', columnDefs, true);

        const editorDataset: any = {
            sections: {
                "MainForm": {
                    "dm_reference": "Role",
                    "name": "MainForm",
                    "height": "100%",
                    "children": [
                        {
                            "dm_reference": ACTIVITY_TRACKING_FIELD,
                            "type": "Grid",
                            "align_label": "Hidden",
                            "hide_grid_filter": true,
                            // "floating_grid_filter": true,
                            "allow_multi_select": true,
                            "height": "100%",
                            "children": datasetConfig.columns
                        }
                    ]
                }
            },
            field_configurations: datasetConfig.fieldConfigurations,
            rules: datasetConfig.rules,
            menus: datasetConfig.menus,
            default_date_format: "ISO8601",
            default_time_format: "string"
        };

        return editorDataset;
    }

    private diGetEntitiesQuery(entity_name: string, params: LinkToEntityQueryParams): Promise<LinkToEntityQueryResult> {
        const queryParams: ZappsmithWebServiceQueryParams = {
            include_count: params.include_count,
            limit: params.limit,
            offset: params.offset,
            order_by: params.order_by
        }

        // Remove #value from parm_dict keys
        const newParmDict = params.param_dict;
        Object.keys(newParmDict).forEach(key => {
            const newKey = key.replace('.#value', '');
            newParmDict[newKey] = newParmDict[key];
            delete newParmDict[key];
        });
        queryParams.param_dict = newParmDict;

        // Add Selected Report filter
        if (this.reportControl.value?.parmDict) {
            Object.keys(this.reportControl.value?.parmDict).forEach(key => {
                if (!newParmDict[key]) {
                    newParmDict[key] = this.reportControl.value?.parmDict[key];
                }
            });
        }

        const apiParams = new ZappsmithWebServiceApiParams('activity_log', queryParams);

        return new Promise<LinkToEntityQueryResult>((resolve, reject) => {
            this.zappsmithWebService.getApi(apiParams).then(result => {
                const queryResult: LinkToEntityQueryResult = {
                    total_count: result?.count ? result.count : undefined,
                    entities: Array.isArray(result) ? result : result?.records
                };

                queryResult.entities.forEach((entity, index) => {
                    entity.key = (entity as any)._id
                });

                resolve(queryResult);
            })
        });
    }
}

enum ActivityTrackingReportType {
    All = 'All',
    AdminActivity = 'AdminActivity',
    Configuration = 'Configuration',
    DrillerQueries = 'DrillerQueries',
    LoginActivity = 'LoginActivity'
}

interface ActivityTrackingReport {
    type: ActivityTrackingReportType,
    label: string,
    permission: string,
    columnDefs: any[],
    parmDict?: ZappsmithWebServiceParmDict
}

const WhenColumn: any = {
    heading: 'When',
    field: 'created_when',
    kind: 'datetime',
    active: true
}

const UserIdColumn: any = {
    heading: 'User ID',
    field: 'user.id',
    kind: 'text',
    active: true
}

const UserNameColumn: any = {
    heading: 'User Name',
    field: 'user.name',
    kind: 'text',
    active: true
}

const FacilityIdColumn: any = {
    heading: 'Facility ID',
    field: 'facility.id',
    kind: 'text',
    active: true
}

const FacilityNameColumn: any = {
    heading: 'Facility Name',
    field: 'facility.name',
    kind: 'text',
    active: true
}

const ModuleColumn: any = {
    heading: 'Module',
    field: 'module',
    kind: 'text',
    active: true
}

const ActivityColumn: any = {
    heading: 'Activity',
    field: 'activity',
    kind: 'text',
    active: true
}

const TypeColumn: any = {
    heading: 'Type',
    field: 'object_name',
    kind: 'text',
    active: true
}

const TypeKeyColumn: any = {
    heading: 'Type Key',
    field: 'object_key',
    kind: 'text',
    active: true
}

const ContextColumn: any = {
    heading: 'Context',
    field: 'context',
    kind: 'text',
    active: true
}

const KeyColumn: any = {
    heading: 'Key',
    field: 'context_key',
    kind: 'text',
    active: true
}

const AllReport: ActivityTrackingReport = {
    type: ActivityTrackingReportType.All,
    label: 'All',
    permission: 'UserActivityTrackingAdminActivityLevel',
    columnDefs: [
        WhenColumn, UserIdColumn, UserNameColumn, FacilityIdColumn, FacilityNameColumn,
        ModuleColumn, ActivityColumn, TypeColumn, TypeKeyColumn, ContextColumn, KeyColumn
    ]
}

const AdminActivityReport: ActivityTrackingReport = {
    type: ActivityTrackingReportType.AdminActivity,
    label: 'Admin Activity',
    permission: 'UserActivityTrackingAdminActivityLevel',
    columnDefs: [
        WhenColumn, UserIdColumn, UserNameColumn, FacilityIdColumn, FacilityNameColumn,
        ActivityColumn, TypeColumn, KeyColumn
    ],
    parmDict: {
        'object_name': {
            operation: FilterOperationValue.in,
            type: FilterOperationType.string,
            values: ["staff", "app_user", "facility", "role", "app_user_activation", 'account', 'user']
        }
    }
}

const ConfigurationReport: ActivityTrackingReport = {
    type: ActivityTrackingReportType.Configuration,
    label: 'Configuration',
    permission: 'UserActivityTrackingAdminActivityLevel',
    columnDefs: [
        WhenColumn, UserIdColumn, UserNameColumn, FacilityIdColumn, FacilityNameColumn,
        TypeColumn, ContextColumn
    ],
    parmDict: {
        'module': {
            operation: FilterOperationValue.eq,
            type: FilterOperationType.string,
            values: ['Configuration']
        }
    }
}

const DrillerQueriesReport: ActivityTrackingReport = {
    type: ActivityTrackingReportType.DrillerQueries,
    label: 'Driller Queries',
    permission: 'UserActivityTrackingDrillerLevel',
    columnDefs: [
        WhenColumn, UserIdColumn, UserNameColumn, FacilityIdColumn, FacilityNameColumn,
        TypeColumn, ContextColumn
    ],
    parmDict: {
        'module': {
            operation: FilterOperationValue.eq,
            type: FilterOperationType.string,
            values: ['Driller']
        },
        'activity': {
            operation: FilterOperationValue.eq,
            type: FilterOperationType.string,
            values: ['QueryReport']
        }
    }
}

const LoginActivityReport: ActivityTrackingReport = {
    type: ActivityTrackingReportType.LoginActivity,
    label: 'Login Activity',
    permission: 'UserActivityTrackingUserAuthenticationLevel',
    columnDefs: [
        WhenColumn, UserIdColumn, UserNameColumn, FacilityIdColumn, FacilityNameColumn,
        ActivityColumn
    ],
    parmDict: {
        'module': {
            operation: FilterOperationValue.eq,
            type: FilterOperationType.string,
            values: ['services.session.session_server']
        }
    }
}

const ReportList: ActivityTrackingReport[] = [
    AllReport,
    AdminActivityReport,
    ConfigurationReport,
    DrillerQueriesReport,
    LoginActivityReport
]
