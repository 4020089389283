import { Component } from '@angular/core';

@Component({
    selector: 'zs-board-data-pass',
    templateUrl: 'board-data-pass.component.html',
    styleUrls: ['board-data-pass.component.scss']
})
export class BoardDataPassComponent {

}
