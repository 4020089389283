<!-- Loading Spinner -->
<mat-card class="system-update-loading" [hidden]="!showLoadingSpinner">
    <mat-spinner mode="indeterminate" diameter="200">
    </mat-spinner>
</mat-card>

<!-- System Update -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>System Update (Build)</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section>
            <div>
                <button mat-flat-button (click)="resetService()">Reset Service</button>
                <button mat-flat-button (click)="clearCache()">Clear Cache</button>
                <button mat-flat-button (click)="reloadScheduler()">Reload Scheduler</button>
            </div>
        </section>
    </mat-card-content>
</mat-card>

<!-- Configuration Update -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Configuration/External Data Update</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>

            <!-- Customer -->
            <section>
                <mat-form-field>
                    <mat-label>Select a Customer</mat-label>
                    <input matInput *ngIf="!configurationCustomers || configurationCustomers?.length <= 0"
                        [ngModel]="configurationCustomerInput" [ngModelOptions]="{standalone: true}" maxlength="40"
                        size="40" />
                    <mat-select *ngIf="configurationCustomers?.length > 0" [(value)]="configurationCustomerInput"
                        panelWidth="">
                        <mat-option *ngFor="let configurationCustomer of configurationCustomers"
                            [value]="configurationCustomer">{{configurationCustomer}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </section>

            <!-- Revision -->
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Optional Revision</mat-label>
                    <input matInput [(ngModel)]="configurationRevision" [ngModelOptions]="{standalone: true}"
                        maxlength="40" size="40" />
                </mat-form-field>
            </section>

            <!-- Branch -->
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Optional Branch</mat-label>
                    <input matInput [ngModel]="configurationBranch" [ngModelOptions]="{standalone: true}" maxlength="40"
                        size="40" />
                </mat-form-field>
            </section>

            <!-- Clear Configuration -->
            <section>
                <mat-slide-toggle [(ngModel)]="clearConfiguration" [ngModelOptions]="{standalone: true}">Clear
                    Configuration</mat-slide-toggle>
            </section>
        </form>

        <!-- Update Configuration -->
        <section>
            <div>
                <button mat-flat-button (click)="updateConfiguration()">Update Configuration</button>
            </div>
        </section>
    </mat-card-content>
</mat-card>

<!-- Tailoring Update -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Tailoring Update</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>

            <!-- Customer -->
            <section>
                <mat-form-field>
                    <mat-label>Select a Customer</mat-label>
                    <input matInput *ngIf="!tailoringCustomers || tailoringCustomers?.length <= 0"
                        [ngModel]="tailoringCustomerInput" [ngModelOptions]="{standalone: true}" maxlength="40"
                        size="40" />
                    <mat-select *ngIf="tailoringCustomers?.length > 0" [(value)]="tailoringCustomerInput" panelWidth="">
                        <mat-option *ngFor="let tailoringCustomer of tailoringCustomers"
                            [value]="tailoringCustomer">{{tailoringCustomer}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </section>

            <!-- Revision -->
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Optional Revision</mat-label>
                    <input matInput [(ngModel)]="tailoringRevision" [ngModelOptions]="{standalone: true}" maxlength="40"
                        size="40" />
                </mat-form-field>
            </section>
        </form>

        <!-- Update Tailoring -->
        <section>
            <div>
                <button mat-flat-button (click)="loadTailoring()">Update Tailoring</button>
            </div>
        </section>
    </mat-card-content>
</mat-card>

<!-- Board Update -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Board Update</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>

            <!-- Customer -->
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Select a Customer</mat-label>
                    <input matInput *ngIf="!boardCustomers || boardCustomers?.length <= 0"
                        [ngModel]="boardCustomerInput" [ngModelOptions]="{standalone: true}" maxlength="40" size="40" />
                    <mat-select *ngIf="boardCustomers?.length > 0" [(value)]="boardCustomerInput" panelWidth="">
                        <mat-option *ngFor="let boardCustomer of boardCustomers"
                            [value]="boardCustomer">{{boardCustomer}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </section>

            <!-- Revision -->
            <section>
                <mat-form-field appearance="outline">
                    <mat-label>Optional Revision</mat-label>
                    <input matInput [(ngModel)]="boardRevision" [ngModelOptions]="{standalone: true}" maxlength="40"
                        size="40" />
                </mat-form-field>
            </section>
        </form>

        <!-- Load Boards -->
        <section>
            <div>
                <button mat-flat-button (click)="loadBoards()">Load Boards</button>
            </div>
        </section>
    </mat-card-content>
</mat-card>